// @media only screen and (max-width: 767px) {
@include mobile {
  html {
    font-size: 1.9vw;
  }
}

// @media (min-width: 768px) and (max-width: 1023px) {
@include tablet {
  html {
    font-size: 1.5vw;
  }
}

// @media (min-width: 1024px) and (max-width: 1279px) {
@include desktop {
  html {
    font-size: 1vw;
  }
}

// @media (min-width: 1280px) and (max-width: 1365px) {
@include widescreen {
  html {
    font-size: 1vw;
  }
}

// @media (min-width: 1366px) and (max-width: 1919px) {
@include wxga-screen {
  html {
    font-size: 1vw;
  }
}

// @media (min-width: 1920px) {
@include hd-screen {
  html {
    font-size: 1vw;
  }
}
