$tablet-width: 768px;
$desktop-width: 1024px;
$wide-screen: 1280px;
$wxga-screen: 1366px;
$hd-screen: 1920px;

@mixin hd-screen {
  @media (min-width:#{$hd-screen}) {
    @content;
  }
}

@mixin wxga-screen {
  @media (min-width: #{$wxga-screen}) and (max-width: #{$hd-screen - 1px}) {
    @content;
  }
}

@mixin widescreen {
  @media (min-width: #{$wide-screen}) and (max-width: #{$wxga-screen - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) and (max-width: #{$wide-screen - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: #{$tablet-width - 1}) {
    @content;
  }
}
