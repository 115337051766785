@import "src/assets/scss/functions";
@import "src/assets/scss/media";
@import "src/assets/scss/responsive";

$pc: #082469;
$sc: #79BE26;
$white-color:#fff;

@mixin background-opacity($color, $opacity: 0.3) {
  border-color: rgba($color, $opacity);
}
@function hexToRgba($color, $opacity) {
  @return rgba($color, $opacity)
}


$pdc: #022069;
$plc: #1f356c;
$lebalcolor: #526289;
$graycolor:#4C5B80;
$model-title-color:#1f356c;
