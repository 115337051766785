@import "../../variables";

@font-face {
  font-family: "icomoon";
  src: url("#{$assetDirectory}/assets/icons/icomoon.eot?4nxu1x");
  src: url("#{$assetDirectory}/assets/icons/icomoon.eot?4nxu1x#iefix") format("embedded-opentype"),
  url("#{$assetDirectory}/assets/icons/icomoon.ttf?4nxu1x") format("truetype"),
  url("#{$assetDirectory}/assets/icons/icomoon.woff?4nxu1x") format("woff"),
  url("#{$assetDirectory}/assets/icons/icomoon.svg?4nxu1x#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon", sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.5rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e900";
  color: #022069;
}

.icon-change-password-icon:before {
  content: "\e901";
  color: #022069;
}

.icon-head-phone:before {
  content: "\e902";
}

.icon-logout-icon:before {
  content: "\e903";
  color: #022069;
}

.icon-user-icon:before {
  content: "\e904";
  color: #022069;
}

.icon-Interested-icon:before {
  content: "\e905";
}

.icon-Applying-icon:before {
  content: "\e906";
}

.icon-Students-icon:before {
  content: "\e907";
}

.icon-Alumni-icon:before {
  content: "\e908";
}

.icon-filter:before {
  content: "\e909";
  color: #022069;
}

.icon-Contact:before {
  content: "\e90c";
  color: #022069;
}

.icon-eye-icon-hide:before {
  content: "\e90b";
  color: #505253;
}

.icon-eye-icon-black:before {
  content: "\e90a";
  color: #505253;
}

.icon-download:before {
  content: "\e90d";
}

.icon-Share:before {
  content: "\e90e";
}

.icon-close-icon:before {
  content: "\e90f";
}

.icon-search-icon:before {
  content: "\e910";
}

.icon-tour-location:before {
  content: "\e911";
}

.icon-Product-tour:before {
  content: "\e912";
}

.icon-adtalem-contacts:before {
  content: "\e913";
}

.icon-Light-Color-Mode:before {
  content: "\e914";
  color: #022069;
}
.icon-edit-button:before {
  content: "\e915";
}
.icon-Reset-icon:before {
  content: "\e916";
  color: #909ab5;
}
.icon-Others-Icon:before {
  content: "\e917";
  color: #0a1f65;

  @media screen and (min-width: 3300px){
    font-size: 20px;
  }
}
.icon-Diversity-icon:before {
  content: "\e918";
  color: #022069;
}
.icon-Calendar:before {
  content: "\e919";
  color: #526289;
}
.icon-Students:before {
  content: "\e91b";
  color: #fff;
}
.icon-Inquiries:before {
  content: "\e91a";
  color: #fff;
}
.icon-Settings-icon:before {
  content: "\e91c";
  color: #022069;
}
.icon-Profile-Dropdown:before {
  content: "\e91d";
  color: #082469;
}
.icon-attachment:before {
  content: "\e91e";
  color: #082469;
}
.icon-Questions-Feedbacks:before {
  content: "\e91f";
  color: #1f356c;
}
.icon-Group-22817:before {
  content: "\e920";
  color: #082469;
}
.icon-Right-Arrow-3 .path1:before {
  content: "\e923";
  color: #3f4d6f;
}
.icon-Right-Arrow-3 .path2:before {
  content: "\e924";
  color: #fff;
  margin-left: -1em;
}
.icon-Left-Arrow-1 .path1:before {
  content: "\e921";
  color: #3f4d6f;
}
.icon-Left-Arrow-1 .path2:before {
  content: "\e922";
  color: #fff;
  margin-left: -1em;
}
.icon-Up-arrow--Active:before {
  content: "\e925";
  color: #082469;
}