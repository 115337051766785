/* You can add global styles to this file, and also import other style files */
@import "variables";
@import "bootstrap";
@import "assets/scss/sass-variables";
@import "assets/scss/icons";
@import "~@ng-select/ng-select/themes/default.theme.css";

/* styles.scss */
html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  background-color: #f5f5f6;
}

ul {
  list-style: none;
  padding-left: 0;
}

p,
h1,
h2,
h3,
h4,
h5 {
  line-height: 1;
}

.pp-bg-primary {
  background-color: $pc;
}

h6 {
  font-size: 1rem;
}

button.btn {
  font-size: 14px;
  font-weight: bold;
  color: $pc;
  border: 1px solid $pc;
  min-width: 100px;
  border-radius: 6px;

  &:hover {
    border-color: $pc;
  }
}

header {
  background-color: $pc;

  @media screen and (max-width: 599px) {
    padding: 0;
    margin: auto;
  }
}

footer {
  background-color: white;
  margin-bottom: 0;
  color: #526289;
  padding: 6px 5px;
  text-align: center;
  position: relative;
  margin-top: 20px;

  @media screen and (max-width: 599px) {
    width: 95vw;
    margin-top: 20px;

    &.customFooter {
      margin-bottom: 100px;
    }
  }

  @media screen and (max-width: 499px) {
    width: 94vw;
  }

  @media screen and (max-width: 399px) {
    width: 93vw;
  }

  small {
    width: 100%;
    text-align: center;

    @media screen and (max-width: 991px) {
      font-size: 9px;
    }

    @media screen and (max-width: 599px) {
      font-size: 11px;
      white-space: break-spaces;
      display: block;
    }
  }
}

.card {
  background-color: white;
  border-radius: 1rem;
  padding: 0.5rem;
  border: none;

  @include media-breakpoint-up(lg) {
    min-height: 200px;
  }
  
  &.card-maxwidth {
    .filter-card {
      max-width: 90vw;
      min-width: 90vw;
      left: 10%;

      @media screen and (max-width: 1199px) {
        left: 3%;
        top: 10%;
      }

      @media screen and (max-width: 991px) {
        left: 1%;
        top: 14%;
      }

      @media screen and (max-width: 767px) {
        left: 1%;
        top: 9%;
        max-width: 93vw;
        min-width: 93vw;
      }

      .popupHead {
        .container {
          margin: 0;
          margin-bottom: 15px;
          width: 100%;
          max-width: unset;
        }

        .container1 {
          flex-direction: unset;

          .filterSubHead {
            width: 87vw;

            .col-custom {
              &:first-child {
                flex: 0 0 auto;
                width: 30%;
              }

              flex: 0 0 auto;
              width: 14%;
            }
          }
        }
      }
    }
  }
}

p {
  margin-bottom: 0;
}

.pp-text-primary {
  color: $pc;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white-color inset !important;
  background-color: $white-color !important;
  font-size: rem(16) !important;

  @media screen and (max-width: 479px) {
    font-size: 10px !important;
  }
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white-color inset !important;
  background-color: $white-color !important;
  font-size: rem(16) !important;

  @media screen and (max-width: 479px) {
    font-size: 10px !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $white-color !important;
  cursor: not-allowed;
}

ngb-modal-backdrop.modal-backdrop.show {
  background-color: rgba(82, 98, 137, 0.9);
}

// edge password eye remove
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.filter button.btn.pp-button {
  position: relative;
  text-align: left;
  padding-left: rem(35);
  width: rem(150);
  height: rem(38);
  font-size: rem(16);
  line-height: 1;
  min-width: unset;
  border: 1px solid #082469;

  @media screen and (max-width: 991px) {
    height: rem(45);
    font-size: rem(18);
    padding-left: rem(45);
  }

  @media screen and (max-width: 767px) {
    width: rem(85);
    height: rem(40);
    background-color: #f1f3f7;
    border: 1.5px solid #c7cedd;
    font-size: rem(16);
    color: $pc;
    padding: rem(10) rem(15);
    text-align: center;

    &::after,
    &::before {
      content: none !important;
    }
  }
  @media screen and (min-width: 3300px) {
    border-radius: 12px;
  }
  &::before {
    content: "\e909";
    color: #022069;
    position: absolute;
    width: rem(16);
    height: rem(16);
    top: 10px;
    left: 10px;
    font-family: "icomoon", sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 1rem;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    @media screen and (min-width: 3300px) {
      top: 20px;
      left: 15px;
    }
  }

  &::after {
    content: "\e900";
    color: #022069;
    position: absolute;
    top: 10px;
    right: 8px;
    font-family: "icomoon", sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 1rem;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    @media screen and (min-width: 3300px) {
      top: 20px;
      right: 10px;
    }
  }
}

app-vertical-bar-chart, app-half-doughnut {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

.mobNavActive {
  .mobile-nav-wrap {
    left: 0 !important;
    transition: all 0.4s cubic-bezier(0, 0, 0.58, 1);
    -o-transition: all 0.4s cubic-bezier(0, 0, 0.58, 1);
    -webkit-transition: all 0.4s cubic-bezier(0, 0, 0.58, 1);
  }
}

.mobNavMenuActive {
  .menu-bar-wrap {
    span {
      &:first-child {
        -webkit-transform: rotate(-45deg) translate(-5px, 5px);
        transform: rotate(-45deg) translate(-5px, 5px);
        transition: all 0.3s ease-in-out;
      }

      &:nth-child(2) {
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }

      &:last-child {
        -webkit-transform: rotate(45deg) translate(-6px, -6px);
        transform: rotate(45deg) translate(-6px, -6px);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.form-group-password {
  position: relative;

  span {
    position: absolute;
    right: rem(15);
    bottom: rem(15);
    cursor: pointer;
    width: rem(24);
    max-width: rem(24);
    height: rem(16);
    max-height: rem(16);
    background-repeat: no-repeat;
    background-size: cover;

    &.invalid-password {
      right: rem(32);
    }
  }

  input[type="text"]~span {
    opacity: 0.6;

    &:after {
      content: "\e90b";
      color: #022069;
      position: absolute;
      width: rem(14);
      height: rem(14);
      top: 0;
      left: 0;
      font-family: "icomoon", sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      font-size: 1rem;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
    }

    @media screen and (max-width: 490px) {
      font-size: 10px !important;
      bottom: 20px;
    }
  }

  input[type="password"]~span {
    opacity: 0.6;

    &:after {
      content: "\e90a";
      color: #022069;
      position: absolute;
      width: rem(14);
      height: rem(14);
      top: 0;
      left: 0;
      font-family: "icomoon", sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      font-size: 1rem;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
    }

    @media screen and (max-width: 479px) {
      font-size: 10px !important;
      bottom: 17px;

      &.invalid-password {
        right: rem(40);
      }
    }
  }
}

// popup
.popupActive {
  .card {
    position: unset;
  }

  .my-scrollbar {
    position: unset;
    height: inherit !important;

    .scroll-main-wrap {
      margin-top: 210px !important;
      padding-left: 10px !important;
    }
  }
}

// custom legend
.custom-legend {
  cursor: default;

  ul {
    display: flex;
    align-items: center;
    margin-bottom: 1.75rem;

    @media screen and (max-width: 599px) {
      height: 20px;
    }

    li {
      color: #526289;
      font-size: rem(12);
      margin-right: rem(20);
      display: flex;
      align-items: center;
      line-height: 1;
      font-weight: 500;

      @media screen and (max-width: 599px) {
        font-size: 9px;
      }

      >span {
        width: rem(15);
        height: rem(15);
        border-radius: rem(5);
        display: block;
        margin-right: rem(10);
        font-weight: 500;
      }
    }
  }

  &.horizontal-sp {
    ul {
      li {
        &:first-child {
          span {
            background-color: #88bc40;
          }
        }

        &:nth-child(2) {
          span {
            background-color: #00807f;
          }
        }

        &:nth-child(3) {
          span {
            background-color: #906edd;
          }
        }

        &:last-child {
          margin-right: 0;

          span {
            background-color: #f48322;
          }
        }
      }
    }

    &.lightColor {
      ul {
        li {
          &:first-child {
            span {
              background-color: #c29d4d;
            }
          }

          &:nth-child(2) {
            span {
              background-color: #6b2d7d;
            }
          }

          &:last-child {
            margin-right: 0;

            span {
              background-color: #8989b6;
            }
          }
        }
      }
    }
  }
}

.verticle-bar-main-wrap {
  .custom-legend {
    ul {
      li {
        &:first-child {
          span {
            background-color: #84c7f0;
          }
        }

        &:nth-child(2) {
          span {
            background-color: #F3D278;
          }
        }

        &:nth-child(3) {
          span {
            background-color: #fa9885;
          }
        }

        &:nth-child(4) {
          span {
            background-color: #d9a9f4;
          }
        }

        &:last-child {
          margin-right: 0;

          >span {
            background-color: #f5b171;
          }
        }
      }
    }
  }

  &.lightColor {
    .custom-legend {
      ul {
        li {
          &:first-child {
            span {
              background-color: #d0ac62;
            }
          }

          &:nth-child(2) {
            span {
              background-color: #9BE2BE;
            }
          }

          &:nth-child(3) {
            span {
              background-color: #9797b9;
            }
          }

          &:nth-child(4) {
            span {
              background-color: #e9838b;
            }
          }

          &:last-child {
            margin-right: 0;

            >span {
              background-color: #85d6ec;
            }
          }
        }
      }
    }
  }
}

.navbar-brand {
  @media screen and (max-width: 575px) {
    z-index: 999999;
  }
}

.ng-value-container,
.ng-option {
  color: $pc;
  font-size: 10px;
  text-transform: capitalize;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    outline: none;
  }
}

app-applicant-enrollment {
  height: 100%;

  .student-enrollments-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.Contact.Executives ng-scrollbar {
  width: 99.94% !important;
}
.custom-ng-select .ng-select .ng-arrow-wrapper,
.custom-ng-select .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  display: none;
}

.custom-ng-select .ng-select .ng-select-container {
  border: none;
  background-image: url("#{$assetDirectory}/assets/images/Search-icon.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 16px 16px;
  margin-left: 1rem;
  width: auto !important;
  padding-right: 30px;
  /* Leave space for the icon */
}

.custom-ng-select .ng-dropdown-panel.ng-select-bottom {
  opacity: 0 !important;
}

.custom-ng-select .ng-select.ng-select-single .ng-select-container .ng-value-container {
  font-size: 1rem;
}

.custom-ng-select .ng-select .ng-select-container .ng-value-container .ng-input>input,
.custom-ng-select .ng-select.ng-select-single .ng-select-container .ng-value-container {
  padding-left: 1.2rem;
  font-size: 1rem;
}

.custom-ng-select .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: 1rem;
}

.custom-ng-select .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  padding-left: 1rem;
}

.custom-ng-select .ng-select .ng-has-value .ng-placeholder {
  display: block;
}

.change-password-popup .ng-scroll-content {
  display: flex !important;
  flex-wrap: wrap;
  padding: 15px 0 0 15px;

  @media screen and (max-width: 575px) {
    padding: 15px;
  }

  >p {
    position: absolute;
    display: flex;
    align-items: center;
    height: 60vh;
    justify-content: center;
    width: 100%;
  }
}

.ha-search {
  .ng-dropdown-panel {
    max-height: 115px;
  }

  .ng-select-container {
    border: 1px solid $pc !important;
    border-radius: 10px !important;

    .ng-value-container {
      .ng-input {
        height: 100%;
        top: 0 !important;

        input {
          height: 100%;
        }
      }

      .ng-placeholder {
        color: $pc;
        font-weight: 500;
        position: relative;
        padding-left: 6px;
        left: 10px;

        @media screen and (min-width: 3300px) {
          font-weight: 700;
          font-size: 20px;
        }

        &:before {
          font-family: "icomoon", sans-serif;
          position: absolute;
          left: -12px;
          top: -2px;
          content: "\e910";
          display: inline-block;
          margin-right: 3px;
          height: 15px;
          overflow: hidden;
          font-size: 15px;
          line-height: -2px;
          @media screen and (min-width: 3300px) {
            font-size: 17px;
            left: -15px;
            top: 4px;
            height: 20px;
            line-height: -5px;
          }
        }
      }
    }
  }

  .ng-arrow-wrapper {
    display: none;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  border: none;
  margin-top: 8px;
  border-radius: 10px !important;
  overflow: hidden;
  -webkit-box-shadow: 0 0 17px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 17px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 17px 3px rgba(0, 0, 0, 0.2);
}

/* Style the custom scrollbar */
.ng-dropdown-panel-items.scroll-host::-webkit-scrollbar {
  width: 4px;
  /* Adjust the scrollbar width */
}

.ng-dropdown-panel-items.scroll-host::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Adjust the scrollbar thumb color */
  border-radius: 4px;
  /* Adjust the scrollbar thumb border-radius */
}

.ng-dropdown-panel-items.scroll-host::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Adjust the scrollbar thumb color on hover */
}

@media (max-width: 575.98px) {
  .chip-container {
    &:before {
      display: none !important;
    }
  }
}

/* Alpha Filter for hospital */
.scroll-container {
  &.alpha-border {
    .after-border {
      width: 85%;
    }
  }
}

.nav-container {
  &.isScroll {
    .not-now-main-wrap {
      top: -104px;

      @media (max-width: 599px) {
        top: auto;
      }
    }
  }
}

.ng-scroll-content {
  display: block !important;
}

.specific-leaner-class {
  .filter-card {
    width: 88vw !important;
    left: auto !important;
    right: rem(16) !important;
    max-width: 1335px !important;

    @media screen and (min-width: 3300px) {
      max-width: 2600px !important;
      right: rem(20);
    }
  }
}

.popup-left {
  .card {
    left: -16% !important;
    max-width: 725px;
    @media screen and (max-width: 1199px) {
      left: auto !important;
    }
  }
}

//faq
@media (max-width: 767px) {
  header {
    .header-container {
      .content {
        .faq-header {
          .faq-img {
            display: none;
          }
        }
      }
    }
  }
  header.customHeader {
    background: #fff;

    .header-container {
      nav.navbar {
        padding-left: 40px;
        z-index: 1;

        @media (max-width: 599px) {
          padding-left: 50px;
          box-shadow: 0 20px 33px #12307924;

          .menu-bar-wrap {
            display: none;
          }
        }
      }

      .content {
        padding: 0;
        transition: none;

        &.isScroll {
          opacity: 1;
          height: 70px;
        }

        @media (max-width: 599px) {
          padding: 0 15px;
          z-index: 1;
          position: relative;
          background-color: #fff;
        }

        .faq-header {
          height: auto;
          margin-top: 0;
          background: #fff;
          padding-left: 0;

          @media (max-width: 599px) {
            background: transparent;
          }

          span.backButton {
            top: -48px;
            left: 0 !important;
            background-color: #fff !important;
            font-size: 25px !important;

            @media (max-width: 599px) {
              top: -57px;
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }
}

.half-doughnut-main-wrap {
  .custom-legend {
    ul {
      li {
        &:first-child {
          span {
            background-color: #84c7f0;
          }
        }

        &:nth-child(2) {
          span {
            background-color: #F3D278;
          }
        }

        &:nth-child(3) {
          span {
            background-color: #fa9885;
          }
        }

        &:nth-child(4) {
          span {
            background-color: #d9a9f4;
          }
        }

        &:last-child {
          margin-right: 0;

          >span {
            background-color: #f5b171;
          }
        }
      }
    }
  }

  &.lightColor {
    .custom-legend {
      ul {
        li {
          &:first-child {
            span {
              background-color: #d0ac62;
            }
          }

          &:nth-child(2) {
            span {
              background-color: #9BE2BE;
            }
          }

          &:nth-child(3) {
            span {
              background-color: #9797b9;
            }
          }

          &:nth-child(4) {
            span {
              background-color: #e9838b;
            }
          }

          &:last-child {
            margin-right: 0;

            >span {
              background-color: #85d6ec;
            }
          }
        }
      }
    }
  }
}

.question-icon-container {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .question-icon-wraper {
    font-size: 12px;
  }
}

.pointer-cursor {
  cursor: pointer;
}

.margin-left-10 {
  margin-left: 10px;
}

.diversity-align {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.col-xl-5 .student-enrollments-wrap .chart-section .chart-data-main canvas {
  @media (min-width: 1200px) {
    height: 355px !important;
    max-width: 100%;
    width: 100% !important;
  }
}


.datepicker-main-wrap {
  display: flex;
  flex-direction: column;
  padding: 20px;

  .datepicker-wrap {
    width: 100%;
    margin-bottom: 10px;

    ngb-datepicker {
      width: 100%;
      border: 0;

      .ngb-dp-header {
        margin-bottom: 10px;

        .ngb-dp-arrow {
          display: none;
        }

        .ngb-dp-navigation-select {
          display: flex;
          justify-content: space-between;

          select {
            background-color: #EAECF2;
            color: #1F356C;
            width: calc(50% - 5px);
            flex: none;
            border: none;
            border-radius: 4px;
          }
        }
      }

      .ngb-dp-months {
        .ngb-dp-month {
          width: 100%;
          background-color: #EAECF2;

          ngb-datepicker-month {
            .ngb-dp-week {
              .ngb-dp-weekday {
                width: 45px;
                height: 35px;
                color: #526165;
                font-weight: 600;
              }
              .ngb-dp-day {
                width: 45px;
                height: 35px;
                color: #08256A;
                display: flex;
                align-items: center;
                justify-content: center;

                .bg-primary {
                  background-color: #08256A !important;
                  border-radius: 50%;
                }

                &.ngb-dp-today {
                  .btn-light {
                    border-radius: 50%;
                    border: 1px solid #08256A;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .datepicker-footer-wrap {
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    display: flex;

    .apply {
      background-color: #79be26;
      color: #ffffff;
      font-size: rem(16);
      border: none;
      border-radius: 5px;
      height: rem(36);
      width: rem(75);
    }

    .reset {
      font-size: rem(16);
      color: #9399A3;
      background-color: #F3F2F2;
      height: rem(36);
      width: rem(75);
      border: none;
      font-weight: 700;
      border-radius: 5px;
    }
  }
}

app-funnel-chart {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  p {
    ul {
      list-style: disc;
      padding-left: rem(32);
    }
  }
}
.italic-justify-end {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;

  @media screen and (max-width: 575px) {
    justify-content: center;
    margin-right: 0px;
  }
}

.tab-content {
  .ng-scroll-content {
    padding: 0 15px 20px 20px;
  }
}