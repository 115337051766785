/* Pixel to REM */
$browser-context: 16; // Default.resource-categories

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}
